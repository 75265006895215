/* eslint-disable no-case-declarations */
import { createRouter, createWebHistory } from 'vue-router'
import { HUB } from '@/hub'
import Hub from '../views/Hub.vue'
import Login from '../views/Login.vue'
import Recovery from '../views/Recovery.vue'
import Import from '../views/Import.vue'
import UsersList from '../views/UsersList.vue'
import Hierarchy from '../views/Hierarchy.vue'
import Settings from '../views/Settings.vue'
import Licensing from '../views/Licensing.vue'
import Teams from '../views/Teams.vue'
import store from '@/store/index.js'
import Help from '../views/Help.vue'
import { Gems } from '../gems'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/hub",
      name: "Hub",
      component: Hub,
      meta: {
        cleanLayout: false,
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        cleanLayout: true,
        loginLayout: true,
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/recovery",
      name: "recovery",
      component: Recovery,
      props: true,
      meta: {
        cleanLayout: true,
        loginLayout: true,
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/import",
      name: "Import",
      component: Import,
      props: true,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/userslist",
      name: "UsersList",
      component: UsersList,
      props: true,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/hierarchy",
      name: "Hierarchy",
      component: Hierarchy,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/teams",
      name: "Teams",
      component: Teams,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/licensing",
      name: "Licensing",
      component: Licensing,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/logout",
      component: Login,
      meta: {
        cleanLayout: true,
        loginLayout: true,
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/login",
      component: Login,
      meta: {
        cleanLayout: true,
        loginLayout: true,
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/help",
      name: "Help",
      component: Help,
      meta: {
        cleanLayout: true,
        loginLayout: false,
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ]
})

async function LogOut() {
  await HUB.CallBackEnd({ action: HUB.Action("logout") }).then((response) => {

    if (response.status == 200) {
      localStorage.removeItem("publicKey");
    }

  });
}

async function ConfirmToken(token, entity) {
  return await HUB.CallBackEnd({
    action: HUB.Action("recovery"), data: {
      token: token,
      entity: entity,
    }
  })
}



router.afterEach(() => {
  document.body.scrollTop = 0;
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.requiresAuth)

  document.title = "inPeopleHub - People Inspiring Performance"

  switch (to.path) {
    case '/logout':
      store.commit('showMFA', false)
      LogOut();
      next();
      break;
    case '/':
      if (from.path == '/') {
        let response = await HUB.CallBackEnd({ action: HUB.Action("check-credentials") })

        if (!response.error && response.data.status != 0)
          next("/hub")
        else
          next()
      } else {
        next()
      }
      break;
    case "/recovery":
      const url = new URL(window.location.href)

      if (url.search == '')
        next("/")

      const path = url.search.split("?")[1]
      const [token, entity] = path.split("&")

      if (!token)
        next("/")

      const response = await ConfirmToken(token, entity)

      if (response.error == true)
        next(`/logout`)

      Object.assign(to.params, {
        userId: response.data.user_id,
        username: response.data.username,
        entity
      })

      next()
      break;

    default:
      await HUB.CallBackEnd({ action: HUB.Action("check-credentials") })

      if (authRequired)
        await store.dispatch('Authentication')

      if (!HUB.isAdmin() && to.meta.requiresAdmin) {
        Gems.Telegram("Acesso negado", "tele-not-allowed")
        next('/hub')
      }

      if (to.path == '/hub') {
        let releases = await HUB.CallBackEnd({ action: HUB.Action("get-latest-releases") })
        let releasesArr = []
        let activateModal = false

        if (Object.keys(releases.data).length > 0 && !releases.data.error) {
          releasesArr = releases.data
          activateModal = true
        }

        Object.assign(to.params, { releasesArr, activateModal })
      }

      next()
  }
})

router.beforeResolve((to) => {

  if (to.path != '/' && to.path != '/logout')
    return

  if ((history.state?.sessionChanged && history.state.message !== null)) {
    let telegramStyle = 'tele-success'
    let msg = history.state.message

    Gems.Telegram(msg, telegramStyle)

    history.state.message = null
    history.state.sessionChanged = null
  }

})

export default router
